import { AbsenceType } from "./Absence";
import { Variant } from "./CompanyMeta";
import { Currency, FALLBACK_CURRENCY } from "./Currency";
import { Employee } from "./Employee";
import { OperatingUnit } from "./OperatingUnit";
import { OrderStatus } from "./OrderStatus";
import { PaymentMethodStatus } from "./PaymentMethodStatus";
import { RentalOrderStatus } from "./RentalOrder";

export const MIN_CUSTOMER_NUMBER = 10001;
export const DEFAULT_ORDER_NUMBERS_START = 700_000;
export const DEFAULT_CREDITOR_NUMBERS_START = 70_000;

export function getDefaultOrderCounters(): AppCompanyCounters["orders"] {
    return {
        [OrderStatus.DRAFT]: 0,
        [OrderStatus.ASSIGNED]: 0,
        [OrderStatus.IN_PROGRESS]: 0,
        [OrderStatus.DONE]: 0,
        [OrderStatus.CHECKED]: 0,
        [OrderStatus.BILLED]: 0,
    };
}

export function getDefaultRentalCounters(): AppCompanyCounters["rentals"] {
    return {
        [OrderStatus.DRAFT]: 0,
        [OrderStatus.IN_PROGRESS]: 0,
        [OrderStatus.DONE]: 0,
        [OrderStatus.CHECKED]: 0,
        [OrderStatus.BILLED]: 0,
    };
}

type AppCompanyCounters = {
    nextOrderNumber: number;
    nextCustomerNumber: number;
    nextCreditorNumber: number;
    orders: Record<OrderStatus, number>;
    rentals: Record<RentalOrderStatus, number>;
    pendingOrderRequests: number;
};

export class AppCompany {
    public id: string;
    public archived: boolean;
    public name?: string;
    public paymentMethodStatus: PaymentMethodStatus;
    public defaultUnitId: OperatingUnit["id"];
    public defaultOperatingUnitName: OperatingUnit["name"];
    public settings: CompanyWideSettings;
    public counters: AppCompanyCounters;

    constructor(initialValues?: Partial<AppCompany>) {
        this.id = initialValues?.id ?? "";
        this.archived = initialValues?.archived ?? false;
        this.name = initialValues?.name;
        this.paymentMethodStatus = initialValues?.paymentMethodStatus ?? PaymentMethodStatus.INITIAL;
        this.defaultUnitId = initialValues?.defaultUnitId ?? "";
        this.defaultOperatingUnitName = initialValues?.defaultOperatingUnitName ?? "";
        this.settings = {
            ...createDefaultAppCompanySettings(Variant.AGRICULTURAL_CONTRACTOR),
            ...initialValues?.settings,
        };
        this.counters = {
            nextCustomerNumber: initialValues?.counters?.nextCustomerNumber ?? MIN_CUSTOMER_NUMBER,
            nextOrderNumber: initialValues?.counters?.nextOrderNumber ?? DEFAULT_ORDER_NUMBERS_START,
            nextCreditorNumber: initialValues?.counters?.nextCreditorNumber ?? DEFAULT_CREDITOR_NUMBERS_START,
            orders: initialValues?.counters?.orders ?? getDefaultOrderCounters(),
            rentals: initialValues?.counters?.rentals ?? getDefaultRentalCounters(),
            pendingOrderRequests: initialValues?.counters?.pendingOrderRequests ?? 0,
        };
    }
}

export type CompanyWideSettings = {
    showVatOnReceipts: boolean;
    showPageNumbersOnReceipts: boolean;
    showLogoOnEveryReceiptPage: boolean;
    currency: Currency;
    showNoteQueryForInternalWork: boolean;
    enableMultiCustomerOrders: boolean;
    showColleaguesInTaskDetailsPage: ShowColleaguesSetting;
    /**
     * @deprecated
     */
    showGeolocationTrackingsInTaskDetailsPage: ShowGeolocationTrackingsSetting;
    showOwnGeolocationTrackingsInTaskDetailsPage: ShowOwnGeolocationTrackingsSetting;
    showColleaguesGeolocationTrackingsInTaskDetailsPage: ShowColleagueGeolocationTrackingsSetting;
    specificEmployeesForGeolocationTracking: Employee["id"][];
    enableCheckedOrderStatus: boolean;
    enableMaschinenring: boolean;
    enableProjects: boolean;
    employeeSettings: EmployeeSettings;
    enableTollCollect: boolean;
    enableOrderStatusAutomation: boolean;
    enableReverseCharge: boolean;
    enableOrderRequests: boolean;
    enableCampaigns: boolean;

    orderActivityPresets: ActivityPreset[];
    internalActivityPresets: ActivityPreset[];
};

export type EmployeeSettings = {
    timeTrackingEditRestriction: TimeTrackingRestriction;
    showTotalOvertime: boolean;
    queryOperatingUnitForInternalWork: boolean;
    absenceTypeColors: Record<AbsenceType, string>;
    greetingText: string;
};

export type TimeTrackingRestriction =
    | { type: TimeTrackingRestrictionType.FULL }
    | { type: TimeTrackingRestrictionType.NONE }
    | { type: TimeTrackingRestrictionType.DAYS; days: number };

export enum TimeTrackingRestrictionType {
    /**
     *  No restriction,
     *  employees can edit time trackings.
     */
    NONE = "TimeTrackingRestrictionType_NONE",
    /**
     * Always restricted,
     * employees can not edit time trackings.
     */
    FULL = "TimeTrackingRestrictionType_FULL",
    /**
     * Restricted for days,
     * employees can edit time trackings for the last X days
     */
    DAYS = "TimeTrackingRestrictionType_DAYS",
}

export enum ShowColleaguesSetting {
    NONE = "ShowColleaguesSetting_NONE",
    SAME_CUSTOMER = "ShowColleaguesSetting_SAME_CUSTOMER",
    ALL = "ShowColleaguesSetting_ALL",
}

export enum ShowGeolocationTrackingsSetting {
    NONE = "ShowGeolocationTrackingsSetting_NONE",
    OWN = "ShowGeolocationTrackingsSetting_OWN",
    SPECIFIC_EMPLOYEES_SAME_CUSTOMER = "ShowGeolocationTrackingsSetting_SPECIFIC_EMPLOYEES_SAME_CUSTOMER",
    SAME_CUSTOMER = "ShowGeolocationTrackingsSetting_SAME_CUSTOMER",
}

export enum ShowOwnGeolocationTrackingsSetting {
    NONE = "ShowOwnGeolocationTrackingsSetting_NONE",
    LAST_24_HOURS = "ShowOwnGeolocationTrackingsSetting_LAST_24_HOURS",
    ONLY_ACTIVE = "ShowOwnGeolocationTrackingsSetting_ONLY_ACTIVE",
    ALL = "ShowOwnGeolocationTrackingsSetting_ALL",
}

export enum ShowColleagueGeolocationTrackingsSetting {
    NONE = "ShowColleagueGeolocationTrackingsSetting_NONE",
    SPECIFIC_EMPLOYEES_SAME_CUSTOMER = "ShowColleagueGeolocationTrackingsSetting_SPECIFIC_EMPLOYEES_SAME_CUSTOMER",
    SAME_CUSTOMER = "ShowColleagueGeolocationTrackingsSetting_SAME_CUSTOMER",
}

export const DEFAULT_EMPLOYEE_SETTINGS: EmployeeSettings = {
    timeTrackingEditRestriction: {
        type: TimeTrackingRestrictionType.DAYS,
        days: 7,
    },
    showTotalOvertime: true,
    queryOperatingUnitForInternalWork: false,
    absenceTypeColors: {
        [AbsenceType.VACATION]: "#49A527",
        [AbsenceType.SICK]: "#D93F4B",
        [AbsenceType.PARENTAL_LEAVE]: "#E57B43",
        [AbsenceType.OTHER]: "#D0D0D0",
        [AbsenceType.TRAINING]: "#5887D3",
    },
    greetingText: "Hallo",
};

export function createDefaultAppCompanySettings(variant: Variant): CompanyWideSettings {
    return {
        showVatOnReceipts: false,
        showPageNumbersOnReceipts: true,
        showLogoOnEveryReceiptPage: true,
        currency: FALLBACK_CURRENCY,
        showNoteQueryForInternalWork: false,
        enableMultiCustomerOrders: false,
        showColleaguesInTaskDetailsPage: ShowColleaguesSetting.SAME_CUSTOMER,
        showGeolocationTrackingsInTaskDetailsPage: ShowGeolocationTrackingsSetting.SAME_CUSTOMER,
        showOwnGeolocationTrackingsInTaskDetailsPage: ShowOwnGeolocationTrackingsSetting.ALL,
        showColleaguesGeolocationTrackingsInTaskDetailsPage: ShowColleagueGeolocationTrackingsSetting.SAME_CUSTOMER,
        specificEmployeesForGeolocationTracking: [],
        enableCheckedOrderStatus: false,
        enableMaschinenring: false,
        enableProjects: false,
        employeeSettings: DEFAULT_EMPLOYEE_SETTINGS,
        enableTollCollect: false,
        enableOrderStatusAutomation: true,
        enableReverseCharge: false,
        enableOrderRequests: false,
        enableCampaigns: true,
        orderActivityPresets: createDefaultOrderActivityPresets(variant),
        internalActivityPresets: createDefaultInternalActivityPresets(variant),
    };
}

export function getShowColleaguesSettingName(setting: ShowColleaguesSetting) {
    switch (setting) {
        case ShowColleaguesSetting.NONE:
            return "Keine";
        case ShowColleaguesSetting.SAME_CUSTOMER:
            return "Gleicher Kunde";
        case ShowColleaguesSetting.ALL:
            return "Alle";
    }
}

export function getShowOwnGeolocationTrackingsSettingName(setting: ShowOwnGeolocationTrackingsSetting) {
    switch (setting) {
        case ShowOwnGeolocationTrackingsSetting.NONE:
            return "Keine";
        case ShowOwnGeolocationTrackingsSetting.ALL:
            return "Alle";
        case ShowOwnGeolocationTrackingsSetting.LAST_24_HOURS:
            return "Letzte 24 Stunden";
        case ShowOwnGeolocationTrackingsSetting.ONLY_ACTIVE:
            return "Aktiver Auftrag";
    }
}

export function getShowColleaguesGeolocationTrackingsSettingName(setting: ShowColleagueGeolocationTrackingsSetting) {
    switch (setting) {
        case ShowColleagueGeolocationTrackingsSetting.NONE:
            return "Keine";
        case ShowColleagueGeolocationTrackingsSetting.SAME_CUSTOMER:
            return "Gleicher Kunde";
        case ShowColleagueGeolocationTrackingsSetting.SPECIFIC_EMPLOYEES_SAME_CUSTOMER:
            return "Spezifische Mitarbeiter beim gleichen Kunden";
    }
}

export enum ActivityPresetIcon {
    RIDE = "ActivityPresetIcon_RIDE",
    USE = "ActivityPresetIcon_USE",
    WAIT = "ActivityPresetIcon_WAIT",
    REPAIR = "ActivityPresetIcon_REPAIR",
    PAUSE = "ActivityPresetIcon_PAUSE",
    OTHER = "ActivityPresetIcon_OTHER",
    MOUNT = "ActivityPresetIcon_MOUNT",
    WASH = "ActivityPresetIcon_WASH",
    LOAD = "ActivityPresetIcon_LOAD",
    UNLOAD = "ActivityPresetIcon_UNLOAD",
    //
    PERSON_DIGGING = "ActivityPresetIcon_PERSON_DIGGING",
    HAMMER = "ActivityPresetIcon_HAMMER",
    TRUCK_PLOW = "ActivityPresetIcon_TRUCK_PLOW",
    EXCAVATOR = "ActivityPresetIcon_EXCAVATOR",
    CLIPBOARD = "ActivityPresetIcon_CLIPBOARD",
    DESKTOP = "ActivityPresetIcon_DESKTOP",
    CLOUD_RAIN = "ActivityPresetIcon_CLOUD_RAIN",
    CARROT = "ActivityPresetIcon_CARROT",
    TRUCK = "ActivityPresetIcon_TRUCK",
    TREE = "ActivityPresetIcon_TREE",
    SEEDLING = "ActivityPresetIcon_SEEDLING",
    WHEAT_AWN = "ActivityPresetIcon_WHEAT_AWN",
    PEN_FIELD = "ActivityPresetIcon_PEN_FIELD",
    GRAPES = "ActivityPresetIcon_GRAPES",
    CALENDAR_LINES_PEN = "ActivityPresetIcon_CALENDAR_LINES_PEN",
    BUILDING_WHEAT = "ActivityPresetIcon_BUILDING_WHEAT",
    BRIGHTNESS = "ActivityPresetIcon_BRIGHTNESS",
    SNOWFLAKE_DROPLETS = "ActivityPresetIcon_SNOWFLAKE_DROPLETS",
    USER_HELMET_SAFETY = "ActivityPresetIcon_USER_HELMET_SAFETY",
    PHONE_OFFICE = "ActivityPresetIcon_PHONE_OFFICE",
    TRACTOR = "ActivityPresetIcon_TRACTOR",
    CORN = "ActivityPresetIcon_CORN",
    USER_COWBOY = "ActivityPresetIcon_USER_COWBOY",
    TREES = "ActivityPresetIcon_TREES",
    COW = "ActivityPresetIcon_COW",
    PIG = "ActivityPresetIcon_PIG",
    SHEEP = "ActivityPresetIcon_SHEEP",
    HORSE_SADDLE = "ActivityPresetIcon_HORSE_SADDLE",
    FORKLIFT = "ActivityPresetIcon_FORKLIFT",
    TRUCK_TOW = "ActivityPresetIcon_TRUCK_TOW",
    CONTAINER_STORAGE = "ActivityPresetIcon_CONTAINER_STORAGE",
    HAND_HOLDING_SEEDLING = "ActivityPresetIcon_HAND_HOLDING_SEEDLING",
    BAG_SEEDLING = "ActivityPresetIcon_BAG_SEEDLING",
    SHOVEL = "ActivityPresetIcon_SHOVEL",
    ROAD_BARRIER = "ActivityPresetIcon_ROAD_BARRIER",
    WAREHOUSE = "ActivityPresetIcon_WAREHOUSE",
    GARAGE_OPEN = "ActivityPresetIcon_GARAGE_OPEN",
    WHEAT = "ActivityPresetIcon_WHEAT",
}

export enum ActivityPresetPurpose {
    PAUSE = "ActivityPresetPurpose_PAUSE",
    USE = "ActivityPresetPurpose_USE",
}

export type ActivityPreset = {
    id: string;
    archived: boolean;
    icon: ActivityPresetIcon;
    name: string;
    /**
     * indicate the special purpose of this activity.
     * `null` means no special meaning
     */
    purpose: ActivityPresetPurpose | null;
};

export function createDefaultOrderActivityPresets(variant: Variant): ActivityPreset[] {
    const pausePreset: ActivityPreset = {
        id: "PAUSE",
        archived: false,
        icon: ActivityPresetIcon.PAUSE,
        name: "Pause",
        purpose: ActivityPresetPurpose.PAUSE,
    };
    const usePreset: ActivityPreset = {
        id: "USE",
        archived: false,
        icon: ActivityPresetIcon.USE,
        name: "Einsatzzeit",
        purpose: ActivityPresetPurpose.USE,
    };

    const commonPresets: ActivityPreset[] = [
        { id: "RIDE", archived: false, icon: ActivityPresetIcon.RIDE, name: "Fahrtzeit", purpose: null },
        usePreset,
        { id: "WAIT", archived: false, icon: ActivityPresetIcon.WAIT, name: "Warten", purpose: null },
        { id: "REPAIR", archived: false, icon: ActivityPresetIcon.REPAIR, name: "Reparieren", purpose: null },
        pausePreset,
        { id: "OTHER", archived: false, icon: ActivityPresetIcon.OTHER, name: "Sonstiges", purpose: null },
    ];

    switch (variant) {
        case Variant.LOGISTICS:
            return [
                { id: "LOAD", archived: false, icon: ActivityPresetIcon.LOAD, name: "Beladen", purpose: null },
                { id: "UNLOAD", archived: false, icon: ActivityPresetIcon.UNLOAD, name: "Entladen", purpose: null },
                ...commonPresets,
            ];
        case Variant.AGRICULTURAL_CONTRACTOR:
            return [
                { id: "MOUNT", archived: false, icon: ActivityPresetIcon.MOUNT, name: "Rüsten", purpose: null },
                { id: "WASH", archived: false, icon: ActivityPresetIcon.WASH, name: "Waschen", purpose: null },
                ...commonPresets,
            ];
    }
}

export function createDefaultInternalActivityPresets(variant: Variant): ActivityPreset[] {
    const pausePreset: ActivityPreset = {
        id: "PAUSE",
        archived: false,
        icon: ActivityPresetIcon.PAUSE,
        name: "Pause",
        purpose: ActivityPresetPurpose.PAUSE,
    };
    const usePreset: ActivityPreset = {
        id: "USE",
        archived: false,
        icon: ActivityPresetIcon.USE,
        name: "Einsatzzeit",
        purpose: ActivityPresetPurpose.USE,
    };

    const commonPresets: ActivityPreset[] = [
        usePreset,
        { id: "REPAIR", archived: false, icon: ActivityPresetIcon.REPAIR, name: "Reparieren", purpose: null },
        pausePreset,
        { id: "OTHER", archived: false, icon: ActivityPresetIcon.OTHER, name: "Sonstiges", purpose: null },
    ];

    switch (variant) {
        case Variant.LOGISTICS:
            return [
                ...commonPresets,
                { id: "LOAD", archived: false, icon: ActivityPresetIcon.LOAD, name: "Beladen", purpose: null },
                { id: "UNLOAD", archived: false, icon: ActivityPresetIcon.UNLOAD, name: "Entladen", purpose: null },
            ];
        case Variant.AGRICULTURAL_CONTRACTOR:
            return [
                ...commonPresets,
                { id: "WASH", archived: false, icon: ActivityPresetIcon.WASH, name: "Waschen", purpose: null },
                { id: "MOUNT", archived: false, icon: ActivityPresetIcon.MOUNT, name: "Rüsten", purpose: null },
            ];
    }
}

import { AppCompany } from "@farmact/model/src/model/AppCompany";
import { Customer } from "@farmact/model/src/model/Customer";
import { Machine } from "@farmact/model/src/model/Machine";
import { MachineCounterType } from "@farmact/model/src/model/MachineCounterTracking";
import { OperatingUnit } from "@farmact/model/src/model/OperatingUnit";
import {
    ActiveTime,
    AnyDriverQuery,
    DriverQuery,
    DriverQueryBeforeAfter,
    DriverQueryBeforeAfterHistoryEntry,
    DriverQueryResourceOnly,
    DriverQueryResourceWithAmount,
    DriverQueryResourceWithAmountHistoryEntry,
    DriverQuerySingleValue,
    DriverQuerySingleValueHistoryEntry,
    DriverQueryYesNo,
    DriverQueryYesNoHistoryEntry,
    MapStructuresSequence,
    Order,
    ServiceSnapshot,
    TaskRecord,
    isOrder,
} from "@farmact/model/src/model/Order";
import { OrderStatus } from "@farmact/model/src/model/OrderStatus";
import { RentalOrder, isRentalOrder } from "@farmact/model/src/model/RentalOrder";
import { ResourceUsage } from "@farmact/model/src/model/ResourceUsage";
import { DriverQueryInputTime, DriverQueryType } from "@farmact/model/src/model/services/DriverQuery";
import {
    CalculatedResourceDriverQueryBasis,
    CalculatedResourceProtocolActionBasis,
} from "@farmact/model/src/model/services/ResourcePriceBlocks";
import { Service } from "@farmact/model/src/model/services/Service";
import { WorkTypeRestriction } from "@farmact/model/src/model/services/ServicePriceBlocks";
import { ServicePriceUnit } from "@farmact/model/src/model/services/ServicePriceUnit";
import { TaskRecordType } from "@farmact/model/src/model/services/TaskRecords";
import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import { notNullish } from "@farmact/model/src/utils/array";
import { LocaleFormatter } from "@farmact/utils/src/formatters";
import dayjs from "dayjs";
import { produce } from "immer";
import rfdc from "rfdc";
import { v4 } from "uuid";
import { computed } from "./functions";
import { PossibleOperatingUnitIds } from "./PossibleOperatingUnitIds";
import { intersectMany } from "./set";
import { filterTimeTrackingsWithoutBreak } from "./timeTrackingUtils";

export function constructOrderName(service: Service | null | undefined, customerInfo: Customer[] | number | null) {
    const components: string[] = [service?.name ?? "Dienstleistung"];
    if (Array.isArray(customerInfo) && customerInfo.length === 1) {
        components.push(`(bei ${customerInfo[0].displayName})`);
    } else if (Array.isArray(customerInfo) && customerInfo.length > 1) {
        components.push(`(bei ${customerInfo.length} Kunden)`);
    } else if (typeof customerInfo === "number") {
        components.push(`(bei ${customerInfo} Kunden)`);
    }
    return components.join(" ");
}

export function isOrderActive(order: Pick<Order, "activeTime">): boolean {
    return !!(order.activeTime || []).find(time => time.end === null);
}

export function getCurrentlyActiveTime(order: Order): ActiveTime | undefined {
    if (!isOrderActive(order)) {
        return;
    }

    return (order.activeTime || []).find(time => time.start && time.end === null);
}

export function getCurrentlyActiveRunId(order: Order): string | null {
    return getCurrentlyActiveTime(order)?.id ?? null;
}

export function getCurrentlyActiveMachineVariants(order: Order) {
    return getCurrentlyActiveTime(order)?.machineVariants ?? [];
}

/**
 * Stop order to continue work later (order is not finished yet)
 * @param order
 * @returns
 */
export function endActiveTime(order: Order): Partial<Order> {
    return {
        activeTime: (order.activeTime || []).map(time => ({
            ...time,
            end: time.end ?? dayjs().toISOString(),
            customerId: time.customerId,
        })),
    };
}

export function getTrackedMinutesForOrderWithoutBreaks(
    orderId: string,
    timeTrackings: TimeTracking[],
    workTypeRestriction: WorkTypeRestriction | null,
    appCompany: AppCompany
) {
    let relevantTimeTrackings = filterTimeTrackingsWithoutBreak(timeTrackings, appCompany);

    relevantTimeTrackings = relevantTimeTrackings.filter(timeTracking => {
        if (timeTracking.order?.orderId !== orderId) {
            return false;
        }
        if (!workTypeRestriction) {
            return true;
        }
        if (!timeTracking.orderActivityPresetId) {
            return true;
        }

        return workTypeRestriction.includes(timeTracking.orderActivityPresetId);
    });

    return Math.round(
        relevantTimeTrackings
            .map(tt => dayjs(tt.endDateTime).diff(dayjs(tt.startDateTime), "minutes", true))
            .reduce((prev, curr) => prev + curr, 0)
    );
}

type OrderTimeRange = {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
};

export function getStartAndEndDateOfOrder(order: Order | RentalOrder): OrderTimeRange {
    let startDate: dayjs.Dayjs | undefined;
    let endDate: dayjs.Dayjs | undefined;
    if (isRentalOrder(order)) {
        startDate = dayjs(order.plannedStartDateTime ?? undefined);
        endDate = dayjs(order.plannedEndDateTime ?? undefined);
    } else {
        startDate = dayjs(order.displayedStartDateTime ?? undefined);
        endDate = dayjs(order.displayedEndDateTime ?? undefined);
    }
    return { startDate: startDate, endDate: endDate };
}

export function getServiceDatesOfDifferentOrders(orders: (Order | RentalOrder)[]) {
    let earliestDate: dayjs.Dayjs | undefined = undefined;
    let latestDate: dayjs.Dayjs | undefined = undefined;
    orders.forEach(order => {
        const orderDates = getStartAndEndDateOfOrder(order);
        if (!earliestDate || orderDates.startDate.isBefore(earliestDate)) {
            earliestDate = orderDates.startDate;
        }
        if (!latestDate || orderDates.endDate.isAfter(latestDate)) {
            latestDate = orderDates.endDate;
        }
    });
    if (!earliestDate) {
        earliestDate = dayjs();
    }
    if (!latestDate) {
        latestDate = dayjs();
    }
    if (earliestDate.isSame(latestDate, "date")) {
        return earliestDate.format("DD.MM.YYYY");
    } else {
        return earliestDate.format("DD.MM.YYYY") + " - " + latestDate.format("DD.MM.YYYY");
    }
}

export function getAllDriverQueries(order: Order): AnyDriverQuery[] {
    return [
        ...order.driverQueriesYesNo,
        ...order.driverQueriesSingleValue,
        ...order.driverQueriesBeforeAfter,
        ...order.driverQueriesResourceWithAmount,
        ...order.driverQueriesResourceOnly,
    ] as AnyDriverQuery[];
}

/**
 * Check if the given Order has any driver queries
 * @param order
 * @param inputTime
 * @returns
 */
export function getHasDriverQueries(order: Order, inputTime = DriverQueryInputTime.ANY): boolean {
    return (
        filterDriverQueriesByInputTime(order.driverQueriesYesNo, inputTime).length > 0 ||
        filterDriverQueriesByInputTime(order.driverQueriesSingleValue, inputTime).length > 0 ||
        filterDriverQueriesByInputTime(order.driverQueriesBeforeAfter, inputTime).length > 0 ||
        filterDriverQueriesByInputTime(order.driverQueriesResourceWithAmount, inputTime).length > 0 ||
        filterDriverQueriesByInputTime(order.driverQueriesResourceOnly, inputTime).length > 0
    );
}

export function getServiceTaskRecordTypes(serviceSnapshot: ServiceSnapshot | null): TaskRecordType[] {
    const taskRecordTypes: TaskRecordType[] = [];
    if (!serviceSnapshot) {
        return taskRecordTypes;
    }

    if (hasAreaAsPriceComponent(serviceSnapshot)) {
        taskRecordTypes.push(TaskRecordType.AREA);
    }
    if (hasTracksAsPriceComponent(serviceSnapshot)) {
        taskRecordTypes.push(TaskRecordType.TRACKS);
    }
    if (hasTonsAsPriceComponent(serviceSnapshot)) {
        taskRecordTypes.push(TaskRecordType.TONS);
    }
    if (hasCubicMetersAsPriceComponent(serviceSnapshot)) {
        taskRecordTypes.push(TaskRecordType.CUBIC_METERS);
    }
    if (hasLiterAsPriceComponent(serviceSnapshot)) {
        taskRecordTypes.push(TaskRecordType.LITER);
    }
    if (hasLoadsAsPriceComponent(serviceSnapshot)) {
        taskRecordTypes.push(TaskRecordType.LOADS);
    }

    return taskRecordTypes;
}

type TaskRecordBases =
    | ServicePriceUnit
    | MachineCounterType
    | CalculatedResourceDriverQueryBasis
    | CalculatedResourceProtocolActionBasis;

function getServicePriceComponentBases(serviceSnapshot: ServiceSnapshot): Set<TaskRecordBases> {
    const bases = new Set<TaskRecordBases>();

    for (const priceBlock of serviceSnapshot.calculatedServicePriceBlocks) {
        bases.add(priceBlock.basis);
    }
    for (const priceBlock of serviceSnapshot.calculatedResourcePriceBlocks) {
        bases.add(priceBlock.basis);
    }

    return bases;
}

function hasTracksAsPriceComponent(serviceSnapshot: ServiceSnapshot): boolean {
    const bases = getServicePriceComponentBases(serviceSnapshot);
    return bases.has(ServicePriceUnit.KILOMETER) || bases.has(ServicePriceUnit.METER);
}

function hasAreaAsPriceComponent(serviceSnapshot: ServiceSnapshot): boolean {
    const bases = getServicePriceComponentBases(serviceSnapshot);
    return bases.has(ServicePriceUnit.HECTARE) || bases.has(ServicePriceUnit.SQUAREMETER);
}

function hasTonsAsPriceComponent(serviceSnapshot: ServiceSnapshot): boolean {
    const bases = getServicePriceComponentBases(serviceSnapshot);
    return bases.has(ServicePriceUnit.TONS);
}

function hasCubicMetersAsPriceComponent(serviceSnapshot: ServiceSnapshot): boolean {
    const bases = getServicePriceComponentBases(serviceSnapshot);
    return bases.has(ServicePriceUnit.CUBIC_METERS);
}

function hasLiterAsPriceComponent(serviceSnapshot: ServiceSnapshot): boolean {
    const bases = getServicePriceComponentBases(serviceSnapshot);
    return bases.has(ServicePriceUnit.LITER);
}

function hasLoadsAsPriceComponent(serviceSnapshot: ServiceSnapshot): boolean {
    const bases = getServicePriceComponentBases(serviceSnapshot);
    return bases.has(ServicePriceUnit.LOADS);
}

export function filterDriverQueriesByInputTime<T extends AnyDriverQuery>(
    driverQueries: T[],
    inputTime: DriverQueryInputTime
): T[] {
    return driverQueries.filter(driverQuery => {
        switch (driverQuery.type) {
            case DriverQueryType.YES_NO:
            case DriverQueryType.VALUE:
            case DriverQueryType.RESOURCE_WITH_AMOUNT:
            case DriverQueryType.RESOURCE_ONLY:
                return (
                    [inputTime, driverQuery.when].includes(DriverQueryInputTime.ANY) || inputTime === driverQuery.when
                );
            case DriverQueryType.BEFORE_AFTER:
                return true;
        }
    });
}

type FilterDriverQueriesByTypeReturn<T extends DriverQueryType> = T extends DriverQueryType.YES_NO
    ? DriverQueryYesNo
    : T extends DriverQueryType.VALUE
    ? DriverQuerySingleValue
    : T extends DriverQueryType.BEFORE_AFTER
    ? DriverQueryBeforeAfter
    : T extends DriverQueryType.RESOURCE_WITH_AMOUNT
    ? DriverQueryResourceWithAmount
    : T extends DriverQueryType.RESOURCE_ONLY
    ? DriverQueryResourceOnly
    : never;

export function filterDriverQueriesByType<T extends DriverQueryType>(
    driverQueries: AnyDriverQuery[],
    type: T
): FilterDriverQueriesByTypeReturn<T>[] {
    return driverQueries.filter((driverQuery): driverQuery is FilterDriverQueriesByTypeReturn<T> => {
        return driverQuery.type === type;
    });
}

/**
 * Check if the given driver query is required to be filled at the given tigger
 * When `trigger` is STOP all driver queries (at least those with the `required` flag) are needed to be filled.
 * @param driverQuery
 * @param inputTime
 * @returns
 */
export function isDriverQueryRequired(
    driverQuery: AnyDriverQuery,
    inputTime: DriverQueryInputTime.START | DriverQueryInputTime.STOP
) {
    if (inputTime === DriverQueryInputTime.STOP) {
        return driverQuery.required;
    }

    switch (driverQuery.type) {
        case DriverQueryType.YES_NO:
        case DriverQueryType.VALUE:
        case DriverQueryType.RESOURCE_WITH_AMOUNT:
        case DriverQueryType.RESOURCE_ONLY:
            return driverQuery.when === inputTime && driverQuery.required;
        case DriverQueryType.BEFORE_AFTER:
            return driverQuery.required;
    }
}

/**
 * Check if all required driver queries are filled based on the given input time
 * @param order
 * @param inputTime
 * @returns
 */
export function getDidFillDriverQueries(
    order: Order,
    inputTime: DriverQueryInputTime.START | DriverQueryInputTime.STOP
): boolean {
    const allDriverQueries = getAllDriverQueries(order);
    return (
        (!order.serviceSnapshot?.queryNote || !!order.other) &&
        allDriverQueries.every(driverQuery => getDidFillDriverQuery(driverQuery, inputTime))
    );
}

/**
 * Check if the given driver query is filled based on the given input time
 * @param driverQuery
 * @param inputTime
 * @returns
 */
export function getDidFillDriverQuery(driverQuery: AnyDriverQuery, inputTime: DriverQueryInputTime): boolean {
    /**
     * Not required driver queries are always filled
     * because we already provide sensible defaults for its value
     * on creation
     */
    if (!driverQuery.required) {
        return true;
    }

    switch (driverQuery.type) {
        case DriverQueryType.YES_NO: {
            if (inputTime === DriverQueryInputTime.STOP) {
                return typeof driverQuery.value.checked === "boolean";
            }
            if (inputTime === DriverQueryInputTime.START && driverQuery.when === DriverQueryInputTime.START) {
                return typeof driverQuery.value.checked === "boolean";
            }
            return true;
        }
        case DriverQueryType.VALUE: {
            if (inputTime === DriverQueryInputTime.STOP) {
                return typeof driverQuery.value.value === "number";
            }
            if (inputTime === DriverQueryInputTime.START && driverQuery.when === DriverQueryInputTime.START) {
                return typeof driverQuery.value.value === "number";
            }
            return true;
        }
        case DriverQueryType.RESOURCE_WITH_AMOUNT: {
            if (inputTime === DriverQueryInputTime.STOP) {
                return (
                    driverQuery.value.usage !== null &&
                    !!driverQuery.value.usage.resourceId &&
                    !!driverQuery.value.usage.resourceVariantId &&
                    typeof driverQuery.value.usage.amount === "number"
                );
            }
            if (inputTime === DriverQueryInputTime.START && driverQuery.when === DriverQueryInputTime.START) {
                return (
                    driverQuery.value.usage !== null &&
                    !!driverQuery.value.usage.resourceId &&
                    !!driverQuery.value.usage.resourceVariantId &&
                    typeof driverQuery.value.usage.amount === "number"
                );
            }
            return true;
        }
        case DriverQueryType.RESOURCE_ONLY: {
            if (inputTime === DriverQueryInputTime.STOP) {
                return !!driverQuery.value.resource;
            }
            if (inputTime === DriverQueryInputTime.START && driverQuery.when === DriverQueryInputTime.START) {
                return !!driverQuery.value.resource;
            }
            return true;
        }
        case DriverQueryType.BEFORE_AFTER: {
            if (inputTime === DriverQueryInputTime.STOP) {
                return typeof driverQuery.value.before === "number" && typeof driverQuery.value.after === "number";
            }
            if (inputTime === DriverQueryInputTime.START) {
                return typeof driverQuery.value.before === "number";
            }
            return true;
        }
    }
}

export function mergeDriverQueries<T extends DriverQuery>(original: T[], updateData: T[]): T[] {
    const updatedDriverQueries = new Set(updateData.map(driverQuery => driverQuery.id));

    return [...original.filter(driverQuery => !updatedDriverQueries.has(driverQuery.id)), ...updateData];
}

export function mergeTaskRecords(original: TaskRecord[], updatedData: TaskRecord[]) {
    const updatedTaskRecordIds = new Set(updatedData.map(taskRecord => taskRecord.id));
    return [...original.filter(taskRecord => !updatedTaskRecordIds.has(taskRecord.id)), ...updatedData];
}

/**
 * Pushes a new item into the history when the current value is set
 * @param driverQueries
 * @returns
 */
export function updateYesNoHistory(driverQueries: DriverQueryYesNo[]): DriverQueryYesNo[] {
    return driverQueries.map(driverQuery => {
        if (driverQuery.value.checked !== null) {
            const historyEntry: DriverQueryYesNoHistoryEntry = {
                id: v4(),
                timestamp: driverQuery.value.timestamp ?? new Date().toISOString(),
                checked: driverQuery.value.checked,
                customerId: driverQuery.value.customerId,
                orderRunId: driverQuery.value.orderRunId,
                machineVariants: driverQuery.value.machineVariants,
            };
            return {
                ...driverQuery,
                value: {
                    checked: false,
                    timestamp: null,
                    customerId: null,
                    orderRunId: null,
                    machineVariants: [],
                    history: [...driverQuery.value.history, historyEntry],
                },
            };
        }
        return driverQuery;
    });
}

/**
 * Pushes a new item into the history when the current value is set
 * @param driverQueries
 * @returns
 */
export function updateValueHistory(driverQueries: DriverQuerySingleValue[]): DriverQuerySingleValue[] {
    return driverQueries.map(driverQuery => {
        if (driverQuery.value.value !== null) {
            const historyEntry: DriverQuerySingleValueHistoryEntry = {
                id: v4(),
                timestamp: driverQuery.value.timestamp ?? new Date().toISOString(),
                value: driverQuery.value.value,
                customerId: driverQuery.value.customerId,
                orderRunId: driverQuery.value.orderRunId,
                machineVariants: driverQuery.value.machineVariants,
            };
            return {
                ...driverQuery,
                value: {
                    value: null,
                    timestamp: null,
                    customerId: null,
                    orderRunId: null,
                    machineVariants: [],
                    history: [...driverQuery.value.history, historyEntry],
                },
            };
        }
        return driverQuery;
    });
}

/**
 * Pushes a new item into the history when both values are set
 * @param driverQueries
 * @returns
 */
export function updateBeforeAfterHistory(driverQueries: DriverQueryBeforeAfter[]): DriverQueryBeforeAfter[] {
    return driverQueries.map(driverQuery => {
        if (driverQuery.value.before !== null && driverQuery.value.after !== null) {
            const historyEntry: DriverQueryBeforeAfterHistoryEntry = {
                id: v4(),
                timestamp: driverQuery.value.timestamp ?? new Date().toISOString(),
                customerId: driverQuery.value.customerId,
                before: driverQuery.value.before,
                after: driverQuery.value.after,
                orderRunId: driverQuery.value.orderRunId,
                machineVariants: driverQuery.value.machineVariants,
            };
            return {
                ...driverQuery,
                value: {
                    before: null,
                    after: null,
                    timestamp: null,
                    customerId: null,
                    orderRunId: null,
                    machineVariants: [],
                    history: [...driverQuery.value.history, historyEntry],
                },
            };
        }
        return driverQuery;
    });
}

/**
 * Pushes a new item into the history when the current resource usage is set
 * @param driverQueries
 * @returns
 */
export function updateResourceWithValueHistory(
    driverQueries: DriverQueryResourceWithAmount[]
): DriverQueryResourceWithAmount[] {
    return driverQueries.map(driverQuery => {
        if (driverQuery.value.usage !== null) {
            const historyEntry: DriverQueryResourceWithAmountHistoryEntry = {
                id: v4(),
                usage: new ResourceUsage({
                    ...driverQuery.value.usage,
                    customerId: driverQuery.value.customerId,
                    timestamp: driverQuery.value.usage.timestamp ?? new Date().toISOString(),
                    orderRunId: driverQuery.value.orderRunId,
                    machineVariants: driverQuery.value.machineVariants,
                }),
                customerId: driverQuery.value.customerId,
                orderRunId: driverQuery.value.orderRunId,
                machineVariants: driverQuery.value.machineVariants,
            };
            return {
                ...driverQuery,
                value: {
                    usage: null,
                    customerId: null,
                    orderRunId: null,
                    machineVariants: [],
                    history: [...driverQuery.value.history, historyEntry],
                },
            } as DriverQueryResourceWithAmount; // TODO: split up templates;
        }
        return driverQuery as DriverQueryResourceWithAmount; // TODO: split up templates;
    });
}

export function updateResourceOnlyHistory(driverQueries: DriverQueryResourceOnly[]): DriverQueryResourceOnly[] {
    return driverQueries.map(driverQuery => {
        if (driverQuery.value.resource && driverQuery.value.customerId) {
            return {
                ...driverQuery,
                value: {
                    customerId: null,
                    timestamp: null,
                    resource: null,
                    orderRunId: null,
                    machineVariants: [],
                    history: produce(driverQuery.value.history, draft => {
                        const existingHistoryEntryIndex = draft.findIndex(historyEntry => {
                            return historyEntry.customerId === driverQuery.value.customerId;
                        });
                        if (existingHistoryEntryIndex !== -1) {
                            draft[existingHistoryEntryIndex].resource = driverQuery.value.resource;
                            return;
                        }

                        draft.push({
                            id: v4(),
                            customerId: driverQuery.value.customerId,
                            resource: driverQuery.value.resource,
                            timestamp: driverQuery.value.timestamp || new Date().toISOString(),
                            orderRunId: driverQuery.value.orderRunId,
                            machineVariants: driverQuery.value.machineVariants,
                        });
                    }),
                },
            };
        }
        return driverQuery as DriverQueryResourceOnly;
    });
}

/**
 * Get all billable resource usages which where tracked by the driver
 * @param order
 * @param service
 * @returns
 */
export function getBillableDriverQueryResourceUsages(order: Order, service: Service): ResourceUsage[] {
    if (order.serviceId !== service.id) {
        return [];
    }

    const resourceUsages: ResourceUsage[] = [];

    for (const driverQuery of order.driverQueriesResourceWithAmount) {
        const priceBlock = service.driverQueryResourcePriceBlocks.find(priceBlock => {
            return priceBlock.driverQueryTemplate === driverQuery.id;
        });

        /**
         * Only bill resource driver queries the user created
         * a price block for. If none was found, the driver query should not be billed.
         */
        if (!priceBlock) {
            continue;
        }

        if (driverQuery.value.usage) {
            resourceUsages.push(driverQuery.value.usage);
        }

        resourceUsages.push(...driverQuery.value.history.map(historyEntry => historyEntry.usage));
    }

    return resourceUsages;
}

/**
 * Get all unbillable resource usages which where tracked by the driver
 * @param order
 * @param service
 * @returns
 */
export function getUnbillableDriverQueryResourceUsages(order: Order, service: Service): ResourceUsage[] {
    if (order.serviceId !== service.id) {
        return [];
    }

    const resourceUsages: ResourceUsage[] = [];

    for (const driverQuery of order.driverQueriesResourceWithAmount) {
        const priceBlock = service.driverQueryResourcePriceBlocks.find(priceBlock => {
            return priceBlock.driverQueryTemplate === driverQuery.id;
        });

        /**
         * No price block means the user did not create one
         * and therefore this resource driver query should not be billed
         */
        if (priceBlock) {
            continue;
        }

        if (driverQuery.value.usage) {
            resourceUsages.push(driverQuery.value.usage);
        }

        resourceUsages.push(...driverQuery.value.history.map(historyEntry => historyEntry.usage));
    }

    return resourceUsages;
}

export function getResourceUsagesFromDriverQuery(driverQuery: DriverQueryResourceWithAmount): ResourceUsage[] {
    const resourceUsages: ResourceUsage[] = [...driverQuery.value.history.map(historyEntry => historyEntry.usage)];
    if (driverQuery.value.usage) {
        resourceUsages.push(driverQuery.value.usage);
    }

    return resourceUsages;
}

export function createDefaultMapStructuresSequence(order: Order): MapStructuresSequence {
    const sequence = new Set([
        ...order.fieldIds,
        ...order.trackIds,
        ...order.loadingPointIds,
        ...order.unloadingPointIds,
        ...order.markers.map(marker => marker.id),
    ]);

    return Array.from(sequence);
}

export function getAssignableOperatingUnitId(
    ...possibleOperatingUnitIds: Array<PossibleOperatingUnitIds>
): OperatingUnit["id"] | null {
    const definedOperatingUnitIds = possibleOperatingUnitIds.filter(notNullish);
    if (definedOperatingUnitIds.length === 0) {
        return null;
    }

    const intersection = Array.from(
        intersectMany(
            ...definedOperatingUnitIds.map(operatingUnitIds => {
                return new Set(operatingUnitIds);
            })
        )
    );
    if (intersection.length === 1) {
        return intersection[0];
    }

    return null;
}

export function hasAnyBills(order: Order): boolean {
    const billableCustomerIds = new Set(order.customerIds);
    if (order.receiptReceiverId) {
        billableCustomerIds.add(order.receiptReceiverId);
    }

    return Array.from(billableCustomerIds).some(customerId => {
        return order.bills[customerId];
    });
}

function hasAnyDeliveryNotes(order: Order): boolean {
    const billableCustomerIds = new Set(order.customerIds);
    if (order.receiptReceiverId) {
        billableCustomerIds.add(order.receiptReceiverId);
    }

    return Array.from(billableCustomerIds).some(customerId => {
        return order.deliveryNotes[customerId];
    });
}

export function hasAnyReceipts(order: Order): boolean {
    return hasAnyBills(order) || hasAnyDeliveryNotes(order);
}

export function getOrderCustomerIdsWithBill(order: Order): Array<Customer["id"]> {
    return Object.entries(order.bills)
        .filter(([, billId]) => !!billId)
        .map(([customerId]) => customerId);
}

export function getOrderCustomerIdsWithDeliveryNote(order: Order): Array<Customer["id"]> {
    return Object.entries(order.deliveryNotes)
        .filter(([, deliveryNoteId]) => !!deliveryNoteId)
        .map(([customerId]) => customerId);
}

export function getOrderCustomerIdsWithTaskRecords(order: Order): Array<Customer["id"]> {
    const customerIds = new Set<string>();

    for (const taskRecord of order.taskRecords) {
        if (taskRecord.customerId) {
            customerIds.add(taskRecord.customerId);
        }
    }

    return Array.from(customerIds);
}

export function getOrderCustomerIdsWithDriverQueries(order: Order): Array<Customer["id"]> {
    const customerIds = new Set<string>();

    const allDriverQueries = getAllDriverQueries(order);
    for (const driverQuery of allDriverQueries) {
        if (driverQuery.value.customerId) {
            customerIds.add(driverQuery.value.customerId);
        }

        for (const historyEntry of driverQuery.value.history) {
            if (historyEntry.customerId) {
                customerIds.add(historyEntry.customerId);
            }
        }
    }

    return Array.from(customerIds);
}

export function getOrderCustomerIdsWithProtocol(order: Order): Array<Customer["id"]> {
    const customerIds = new Set<string>();

    for (const protocolItem of order.protocol) {
        if (protocolItem.customerId) {
            customerIds.add(protocolItem.customerId);
        }
    }

    return Array.from(customerIds);
}

export function getOrderCustomerIdsWithWeighings(order: Order): Array<Customer["id"]> {
    const customerIds = new Set<string>();

    for (const weighing of order.weighings) {
        for (const entry of weighing.entries) {
            if (entry.customerId) {
                customerIds.add(entry.customerId);
            }
        }
    }

    return Array.from(customerIds);
}

export function getOrderCustomerIdsWithResourceUsages(order: Order): Array<Customer["id"]> {
    const customerIds = new Set<string>();

    for (const resourceUsage of order.resourceUsages) {
        if (resourceUsage.customerId) {
            customerIds.add(resourceUsage.customerId);
        }
    }

    return Array.from(customerIds);
}

const deepCopy = rfdc();

type CopyOrderParams = {
    enableOrderStatusAutomation: boolean;
};

export function copyOrder(orderToCopy: Order, params: CopyOrderParams) {
    let initialStatus = OrderStatus.DRAFT;
    if (params.enableOrderStatusAutomation && orderToCopy.employeeId) {
        initialStatus = OrderStatus.ASSIGNED;
    }

    return new Order(
        deepCopy({
            appUserId: orderToCopy.appUserId,
            billingDisabled: orderToCopy.billingDisabled,
            customerIds: orderToCopy.customerIds,
            projectId: orderToCopy.projectId,
            employeeId: orderToCopy.employeeId,
            fieldIds: orderToCopy.fieldIds,
            trackIds: orderToCopy.trackIds,
            loadingPointIds: orderToCopy.loadingPointIds,
            unloadingPointIds: orderToCopy.unloadingPointIds,
            machineIds: orderToCopy.machineIds,
            markers: orderToCopy.markers,
            name: orderToCopy.name,
            operatingUnitId: orderToCopy.operatingUnitId,
            other: orderToCopy.other,
            serviceId: orderToCopy.serviceId,
            status: initialStatus,
            creatorId: orderToCopy.creatorId,
            mapStructuresSequence: orderToCopy.mapStructuresSequence,
            isMapStructuresSequenceEnabled: orderToCopy.isMapStructuresSequenceEnabled,
        })
    );
}

type GetFormattedOrderDatesParams = {
    dateFormatter: LocaleFormatter<Date | dayjs.Dayjs>;
};

export type FormattedOrderDates = {
    startDate: string | null;
    endDate: string | null;
    startTime: string | null;
    endTime: string | null;
};

// TODO 9412 fix order dates in project orders table
export function getFormattedOrderDates(
    order: Order | RentalOrder,
    params: GetFormattedOrderDatesParams
): FormattedOrderDates {
    const { dateFormatter } = params;

    if (isRentalOrder(order)) {
        if (order.status === OrderStatus.BILLED && order.billingDate) {
            return {
                startDate: dateFormatter.format(dayjs(order.billingDate)),
                startTime: null,
                endDate: null,
                endTime: null,
            };
        }

        return {
            startDate: order.plannedStartDateTime ? dateFormatter.format(dayjs(order.plannedStartDateTime)) : null,
            endDate: computed(() => {
                if (order.status === OrderStatus.IN_PROGRESS) {
                    return "offen";
                }
                if (order.plannedEndDateTime) {
                    return dateFormatter.format(dayjs(order.plannedEndDateTime));
                }
                return "offen";
            }),
            startTime: order.plannedStartDateTime ? dayjs(order.plannedStartDateTime).format("HH:mm") : null,
            endTime: order.plannedEndDateTime ? dayjs(order.plannedEndDateTime).format("HH:mm") : null,
        };
    }

    let startDate: string | null = null;
    let endDate: string | null = null;
    let startTime: string | null = null;
    let endTime: string | null = null;

    if (order.displayedStartDateTime) {
        startDate = dateFormatter.format(dayjs(order.displayedStartDateTime));
        startTime = dayjs(order.displayedStartDateTime).format("HH:mm");
    }
    if (order.displayedEndDateTime) {
        endDate = dateFormatter.format(dayjs(order.displayedEndDateTime));
        endTime = dayjs(order.displayedEndDateTime).format("HH:mm");
    }

    if (order.status === OrderStatus.BILLED && order.billingDate) {
        startDate = dateFormatter.format(dayjs(order.billingDate));
        startTime = null;
        endDate = null;
        endTime = null;
    }

    if (order.status === OrderStatus.IN_PROGRESS) {
        endTime = "offen";
    }

    return {
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
    };
}

export function getOrderMachines(params: {
    order: Order | RentalOrder;
    allMachines: Machine[];
    includePastSnapshots: boolean;
}): Machine[] {
    const { order, allMachines, includePastSnapshots } = params;
    const result: Machine[] = includePastSnapshots && isOrder(order) ? [...order.machineSnapshots] : [];

    for (const machineId of order.machineIds) {
        if (result.find(machine => machine.id === machineId)) {
            continue;
        }
        if (isOrder(order)) {
            const snapshot = order.machineSnapshots.find(snapshot => snapshot.id === machineId);
            if (snapshot) {
                result.push(snapshot);
                continue;
            }
        }

        const machine = allMachines.find(machine => machine.id === machineId);
        if (machine) {
            result.push(machine);
        }
    }

    return result;
}

export function getCommonCustomerIds(orders: Array<Order | RentalOrder>): Set<string> {
    if (orders.length === 0) {
        return new Set();
    }

    const customerIdsSets = orders.map(order => {
        if (isOrder(order)) {
            if (order.receiptReceiverId) {
                return new Set([order.receiptReceiverId]);
            }

            return new Set(order.customerIds);
        }

        return new Set([order.customerId]);
    });

    return intersectMany(...customerIdsSets);
}
